import React, { FC, useEffect, useRef } from "react";

import firebase from "firebase/app";
/** @jsx jsx */
import { Flex, Button, jsx } from "theme-ui";
import Icon from "@mdi/react";
import { mdiHammerWrench } from "@mdi/js";

import { Layout } from "../components";
import { Helmet } from "react-helmet";

const Logo: FC = () => {
  return (
    <div
      sx={{
        width: "170px",
        height: "54px",
      }}
    >
      <span
        sx={{
          fontSize: "36px",
          lineHeight: "54px",
        }}
      >
        <span sx={{ fontWeight: 600 }}>koval</span><span sx={{ fontWeight: 200 }}>soft</span>
      </span>
    </div>
  );
};


const Contact: FC = () => {
  const analytics = useRef<firebase.analytics.Analytics>();

  useEffect(() => {
    analytics.current = firebase.analytics();
  }, []);

  const onMailClick = () => {
    analytics.current?.logEvent("mailto");
  };

  return (
    <Button
      onClick={onMailClick}
      as="a"
      // @ts-ignore
      href="mailto:admin@kovalsoft.com"
      target="_blank"
      rel="noopener noreferrer"
      sx={{
        borderRadius: "39.0625px",
        background: "rgb(82, 129, 191)",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        width: ["125px", "156.25px"],
        height: ["40px", "50px"],
        position: "relative",
      }}
      css={{
        background: "linear-gradient(135deg, rgb(145, 53, 169), rgb(82, 129, 191))",
      }}
    >
      <span
        sx={{
          color: "white",
          textTransform: "uppercase",
          fontWeight: 500,
          fontSize: ["15px", "18.75px"],
          lineHeight: ["22px", "28px"],
          width: ["74px", "92px"],
          height: ["23px", "28px"],
          position: "absolute",
          left: ["calc(50% - 74px/2 + 0.62px)", "calc(50% - 92px/2 - 0.22px)"],
          top: ["calc(50% - 23px/2 + 0.25px)", "calc(50% - 28px/2 - 0.06px)"],
        }}
      >
        Contact
      </span>
    </Button>
  );
};

export const IndexPage: FC = () => {
  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://kovalsoft.com" />
      </Helmet>
      <Layout>
        <Flex sx={{
          minHeight: "100vh",
          flexDirection: "column",
          position: "relative",
        }}>
          <header
            sx={{
              position: "absolute",
              top: "20px",
              left: "30px",
            }}
          >
            <Logo />
          </header>
          <main
            sx={{
              width: "100%",
              flex: "1 0 auto",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Flex
              sx={{
                width: "100%",
                paddingLeft: ["30px", null, "0px"],
              }}
            >
              <Flex
                sx={{
                  width: ["100%", null, "50%"],
                  justifyContent: ["flex-start", null, "flex-end"],
                  alignItems: "center",
                }}
              >
                <div>
                  <h1 sx={{
                    padding: "0px",
                    margin: "0px",
                    fontWeight: "normal",
                    fontSize: ["36px", "64px"],
                    lineHeight: ["54px", "96px"],
                    marginBottom: "20px",
                  }}>
                    Under <br />
                    Construction
                  </h1>
                  <div>
                    <Contact />
                  </div>
                </div>
              </Flex>
              <div
                sx={{
                  display: ["none", null, "block"],
                  width: "50%",
                }}
              >
                <Icon path={mdiHammerWrench} color="rgba(248, 248, 248, 0.79)" size="400px" />
              </div>
            </Flex>
          </main>
        </Flex>
      </Layout>
    </>
  );
};

export default IndexPage;
